/* eslint-disable react/no-danger */
/* eslint-disable camelcase */
import { useState } from 'react'
import { ReactComponent as BurgerIcon } from 'assets/icons/burger.svg'
import { ReactComponent as FavoriteIcon } from 'assets/icons/favorite.svg'
import { ReactComponent as FavoriteSelectedIcon } from 'assets/icons/favorite-selected.svg'
import { ReactComponent as ArrowLeftIcon } from 'assets/icons/arrow-left.svg'
import { LayerFeature } from 'types'
import {
  CHARTIS_SOURCES_IDS, CommentsSignal, getComments,
  getObjectCollections, ObjectCollectionSignal, postComment, SOURCE_NAMES,
  deleteFavoriteObject, FavoritesSignal, postFavoriteObject,
} from 'services'
import { Button, ButtonSize, ButtonStyle, Loader, TextInput } from 'components'
import { useAppSelector, useMountEffect } from 'utils'
import Comment from './Comment'

import './InfoPanel.scss'

type Props = {
  feature: LayerFeature
  onBack?: () => void
}

const TYPE_PROPERTIES = {
  [CHARTIS_SOURCES_IDS.signal]: 'type_installation_fixe_id_libelle',
  [CHARTIS_SOURCES_IDS.localisateur]: 'type_installation_fixe_id_libelle',
  [CHARTIS_SOURCES_IDS.passageNiveau]: 'type_installation_fixe_id_libelle',
  [CHARTIS_SOURCES_IDS.acces]: 'type_installation_fixe_id_libelle',
  [CHARTIS_SOURCES_IDS.dbc]: 'type_installation_fixe_id_mnemo',
  [CHARTIS_SOURCES_IDS.pedale]: 'type_installation_fixe_id_mnemo',
  [CHARTIS_SOURCES_IDS.sousStation]: 'type_electrifications_id_libelle',
  [CHARTIS_SOURCES_IDS.adv]: 'type_appareil_de_voie_id_mnemonique',
  [CHARTIS_SOURCES_IDS.advLine]: 'type_appareil_de_voie_id_mnemonique',
  [CHARTIS_SOURCES_IDS.poste]: 'type_installation_fixe_id_mnemo',
}

const getTypeProperty = (properties: {[key: string]: string}, source: string) => {
  if (!Object.keys(TYPE_PROPERTIES).includes(source)) return ''
  return Object.entries(properties).find(([key]) => key === TYPE_PROPERTIES[source])?.[1] || 'NC'
}

export default function InfoSinglePanel({ feature, onBack }: Props) {
  useMountEffect(() => getComments(feature.source, feature.properties?.id))
  useMountEffect(() => getObjectCollections(feature.source, feature.properties?.id))
  const accountId = useAppSelector(({ user }) => user.account.id)
  const [newComment, setNewComment] = useState('')
  const [view, setView] = useState<'info' | 'comments'>('info')
  const [displayMenu, setDisplayMenu] = useState(false)
  const { properties, sourceLayer } = feature
  const { lrs_ligne, lrs_voie, lrs_pk, libelle, libelle_long, id } = properties || {}
  const favoriteId = FavoritesSignal.objects.value.find(favorite => favorite.object_id === id)?.id

  const handleSelectView = (v: 'info' | 'comments') => () => {
    setView(v)
    setDisplayMenu(false)
  }

  const handlePostFavorite = () => {
    postFavoriteObject({
      object_id: feature?.properties?.id,
      object_libelle: libelle_long || libelle || 'N/C',
      object_lvp: `${lrs_ligne} - ${lrs_voie || ''} ${lrs_pk || ''}`,
      layer_id_field: 'id',
      layer_slug: sourceLayer,
    })
  }

  const handlePostComment = async () => {
    if (newComment) {
      postComment({
        user_id: accountId,
        content: newComment,
        type: 'Divers',
        object_id: feature?.properties?.id,
        object_libelle: libelle || 'N/C',
        layer_id_field: 'id',
        layer_slug: sourceLayer,
      })
      setNewComment('')
    }
  }

  const renderInfo = () => (
    <div className="info">
      <p dangerouslySetInnerHTML={{ __html: `<b>Couche : </b>${SOURCE_NAMES[sourceLayer] || 'N/C'}` }} />
      <p dangerouslySetInnerHTML={{ __html: `<b>Ligne : </b>${lrs_ligne || 'N/C'}` }} />
      <p dangerouslySetInnerHTML={{ __html: `<b>Voie : </b>${lrs_voie || 'N/C'}` }} />
      <p dangerouslySetInnerHTML={{ __html: `<b>PK : </b>${lrs_pk || 'N/C'}` }} />
      {feature?.properties?.num_compte_geremi && (
        <p>
          <b>Numéro de compte : </b>
          {feature?.properties?.num_compte_geremi}
        </p>
      )}
      {feature?.properties?.numero_astre && (
        <p>
          <b>Numéro Astre : </b>
          {feature?.properties?.numero_astre}
        </p>
      )}
      {getTypeProperty(properties, sourceLayer) && (
        <p>
          <b>Type : </b>
          {getTypeProperty(properties, sourceLayer) || 'N/C'}
        </p>
      )}
      <p dangerouslySetInnerHTML={{ __html: `<b>Gaia ID : </b>${id || 'N/C'}` }} />
      <div className="divider" />
      <div className="collections-infos">
        <div className="title">
          <b>Poste(s) de rattachement : </b>
          {ObjectCollectionSignal.loading.value && <Loader variant="x-small" />}
        </div>
        {!ObjectCollectionSignal.loading.value && (
          <div className="items">
            {ObjectCollectionSignal.postes.value.length ? ObjectCollectionSignal.postes.value.map(poste => (
              <Button
                key={poste.id}
                style={ButtonStyle.borderLess}
                text={poste.label}
                size={ButtonSize.medium}
                inline
              />
            )) : 'N/C'}
          </div>
        )}
      </div>
      <div className="divider" />
      <div className="collections-infos">
        <div className="title">
          <b>Collection(s) Dex associée(s) :</b>
          {ObjectCollectionSignal.loading.value && <Loader variant="x-small" />}
        </div>
        {!ObjectCollectionSignal.loading.value && (
          <div className="items">
            {ObjectCollectionSignal.collections.value.length ? ObjectCollectionSignal.collections.value.map(item => (
              <Button
                key={item}
                style={ButtonStyle.borderLess}
                text={item}
                size={ButtonSize.medium}
                inline
              />
            )) : 'N/C'}
          </div>
        )}
      </div>
    </div>
  )

  const renderComments = () => (
    <div className="comments">
      <div className="list hide-scroll">
        {CommentsSignal.value.map(comment => <Comment key={comment.id} comment={comment} />)}
      </div>
      <div className="add-comment">
        <TextInput label="Ajouter un commentaire" bindedValue={newComment} onChange={setNewComment} />
        <Button text="Publier" onClick={handlePostComment} />
      </div>
    </div>
  )

  const getTitle = () => {
    switch (view) {
      case 'info':
        return 'Infos'
      case 'comments':
        return `Commentaires (${CommentsSignal.value.length})`
      default:
        return 'Infos'
    }
  }

  const getView = () => {
    switch (view) {
      case 'info':
        return renderInfo()
      case 'comments':
        return renderComments()
      default:
        return renderInfo()
    }
  }

  return (
    <>
      {!onBack && <h3 className="panel-title">{feature?.properties?.libelle || 'N/C'}</h3>}
      {onBack && (
        <div className="panel-title">
          <Button
            className="button-back"
            style={ButtonStyle.borderLess}
            text=""
            icon={<ArrowLeftIcon />}
            onClick={onBack}
          />
          <h3>{feature?.properties?.libelle || 'N/C'}</h3>
        </div>
      )}
      <div className="info-panel panel">
        <div className="object-single">
          <div className="divider" />
          <div className="header">
            <p>{getTitle()}</p>
            {favoriteId
              ? <FavoriteSelectedIcon onClick={() => deleteFavoriteObject(favoriteId)} />
              : <FavoriteIcon onClick={handlePostFavorite} />}
            <Button
              text=""
              icon={<BurgerIcon />}
              style={ButtonStyle.borderLess}
              onClick={() => setDisplayMenu(!displayMenu)}
            />
            {CommentsSignal.value.length > 0 && <div className="comments-count">{CommentsSignal.value.length}</div>}
            <div className={`menu ${displayMenu ? 'display' : ''}`}>
              <Button
                text="Infos"
                style={ButtonStyle.borderLess}
                onClick={handleSelectView('info')}
                className={view === 'info' ? 'selected' : ''}
              />
              <Button
                text={`Commentaires (${CommentsSignal.value.length})`}
                style={ButtonStyle.borderLess}
                onClick={handleSelectView('comments')}
                className={view === 'comments' ? 'selected' : ''}
              />
            </div>
          </div>
          {feature && getView()}
        </div>
      </div>
    </>
  )
}
